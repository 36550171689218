const { regClass, property } = Laya;
import AppContext from "../app/AppContext";
import { SceneEnum, SoundEnum } from "../help/Help";
import Logger from "../help/Logger";
import Setting from "../help/Setting";
import Battle from "./Battle";
import Mask from "./Mask";

@regClass('i8jfS1YtT5mMQ1B_kJ9K0w')
export default class Single extends Laya.Script {
  /**
   * 单例
   */
  public static me: Single;

  public mask: Mask

  public battle: Battle;

  // 重新开始
  private restart: Laya.Image;

  // 回到首页
  private home:  Laya.Image;
  
  @property({type:Laya.Sprite})
  private gameOverMask: Laya.Sprite;

  constructor() {
    super();
    Single.me = this;
  }

  /**
   * 组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次  onEnable
   */
  onAwake(): void{
    this.mask = this.owner.getChildByName("mask").getComponent(Mask)
    this.battle = this.owner.getChildByName("battle").getComponent(Battle);

    this.gameOverMask = this.owner.getChildByName("over") as Laya.Sprite;

    this.restart = this.gameOverMask.getChildByName("bg").getChildByName("restart") as Laya.Image;
    this.restart.on(Laya.Event.CLICK, this, this.restartGame);

    this.home = this.gameOverMask.getChildByName("bg").getChildByName("home") as Laya.Image;
    this.home.on(Laya.Event.CLICK, this, () => {
      Laya.SoundManager.stopAll()
      Laya.Scene.open(SceneEnum.Home)
    })
  }


  async restartGame() { 
    Logger.info("重新开始")
    let resp = await AppContext.playGame();
    if (resp) {
      this.start();
    }
  }
    
  /**
   * 组件被启用后执行，例如节点被添加到舞台后
   */
  onEnable(): void {
    this.start()
  }

  async start() { 
    // 1. 开启倒计时
    this.battle.prepare();
    this.gameOverMask.visible = false;
    await this.mask.start();

    // 3. 播放开始声音
    Setting.playSound(SoundEnum.GAME_START, 1, Laya.Handler.create(this, () => {
      // 2. 开始游戏
      Logger.info("battle start");
      this.battle.start();
      Laya.SoundManager.stopAll();
      this.battle.playBgm()
    }));
  }
    

  // 进入“大爆炸”玩法
  explosion() {
    this.battle.explosion();
  }

  gameOver() {
    this.gameOverMask.visible = true;
  }

  /**
   * 组件被禁用时执行，例如从节点从舞台移除后
   */
  onDisable(): void {
    Logger.info("[single]-onDisable")
    Laya.SoundManager.stopAll();
  }

  /**
   * 第一次执行update之前执行，只会执行一次
   */
  //onStart(): void {}

  /**
   * 手动调用节点销毁时执行
   */
  onDestroy(): void { 
      //Laya.SoundManager.stopAll();
  }

  /**
   * 每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
   */
  //onUpdate(): void {}

  /**
   * 每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
   */
  //onLateUpdate(): void {}

  /**
   * 鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
   */
  //onMouseClick(): void {}
}