import AppContext from "../app/AppContext";
import PlatformHelp from "../app/PlatformHelp";

const { regClass, property } = Laya;

@regClass('ZSPZFC-fSvyg96vFx2Sk-g')
export class OpenData extends Laya.Script {
    
	@property(Laya.Image)
	public back: Laya.Image;

	public rankImg: Laya.Image;

	private index: number = 0;

	private rankImgs = [
		'resources/rank/friendrank.png',
		'resources/rank/worldrank.png'
	]

	//组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
	//onAwake(): void {}

	//组件被启用后执行，例如节点被添加到舞台后
	onEnable(): void {
		this.back = this.owner.getChildByName("back") as Laya.Image; 
		this.back.on(Laya.Event.CLICK, () => {
			(this.owner as Laya.Sprite).visible = false;
		})

		this.rankImg = this.owner.getChildByName("rankType") as Laya.Image; 
		this.rankImg.skin = this.rankImgs[this.index];
		this.rankImg.on(Laya.Event.CLICK, () => {
			this.index = this.index == 0 ? 1 : 0;
			this.rankImg.skin = this.rankImgs[this.index];
			if (this.index == 0) {
				AppContext.postMessage({
					type: 'updateViewPort'
				});
			} else {
				let gameInfo = PlatformHelp.getGameInfo();
				AppContext.rankWorld({openid:gameInfo.openid}).then(resp => {
					AppContext.postMessage({
						type: 'rankWorld',
						data: JSON.stringify(resp)
					});
				})
			}
		})
	}


	start() { 
		(this.owner as Laya.Sprite).visible = true;
		AppContext.postMessage({
			type: 'updateViewPort',
			data: this.index
		});
	}

	//组件被禁用时执行，例如从节点从舞台移除后
	//onDisable(): void {}

	//第一次执行update之前执行，只会执行一次
	//onStart(): void {}

	//手动调用节点销毁时执行
	//onDestroy(): void {}

	//每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	//onUpdate(): void {}

	//每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	//onLateUpdate(): void {}

	//鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
	//onMouseClick(): void {}
}