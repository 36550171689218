
// 定义接口
enum ApiEnum {

  baseUrl = "https://press.roorz.com/api",
//  baseUrl = "http://127.0.0.1:9500/api",

  // 登录
  LOGIN = "/login",


  // 上传数据
  UPLOAD_DATA = "/upload/data",

  
  // 世界排行
  RANK_WORLD = "/rank/world",


  // 分享获得金币
  SHARE_APLLY = "/share/apply",

  // 玩一把
  PLAY_GAME = "/play/game"
}

export default ApiEnum
