import AppContext from "../app/AppContext";
import { getExp, SceneEnum, SoundEnum } from "../help/Help";
import HelpCache, { ConfigEnum } from "../help/HelpCache";
import Logger from "../help/Logger";
import MathUtils from "../help/MathUtils";
import Setting from "../help/Setting";
import Level from "../model/Level";
import UserInfo from "../model/UserInfo";
import Bob from "./Bob";
import Single from "./Single";

const { regClass, property } = Laya;

@regClass('4ZW-tbBHQ9mUhsJl08gl_w')
export default class Battle extends Laya.Script {
  @property({ type: Bob })
  public bob: Bob;

  @property({ type: Laya.Label })
  private scoreLabel: Laya.Label;

  @property({ type: Laya.Image })
  private bobBtn: Laya.Image;

  @property({ type: Laya.Image })
  private pointer: Laya.Image;

  @property({ type: Laya.Image })
  private back: Laya.Image;

  // 记录当前执行的tween
  private counterClockTween: Laya.Tween;

  // 最大关卡数,最后一关为狂暴
  private levelMax: number = 8;
  // 狂暴bt操作时间递减系数0.05s
  private timeOprReduceR: number = 0.05;
  // 狂暴bt操作时间最小0.45s
  private timeOprMin: number = 1.0;
  // 狂暴阶段bob时间
  private timeBob: number;
  // 是否狂暴后
  private isRage: boolean = false;

  // 所有的按钮
  private btns: Array<Laya.Image> = new Array(10);

  // 当前关卡的总时间
  private timeOprLevel: number = 0;
  //单回合操作用时
  private timeOprOnce: number = 0;

  // 当前每个点击执行的时间
  private useTime: number = 0;

  //答对选项个数
  private rightCount: number = 0;

  //当前正确答案
  private rightAnwsers: Array<number> = new Array<number>();
  //当前回答
  private anwsers: Array<number> = new Array<number>();

  //游戏玩法，0常规、1大爆炸
  private play: number = 0;

  //游戏状态，0准备、1出题、2答题、3结束
  private state: number = 0;

  // 获取所有的配置
  private levels: Array<Level>;

  // 当前用户的额信息
  private userInfo: UserInfo;

  // 当前关卡的配置
  private curLevel: Level;

  // 当前执行的版本
  private winLevel: number = 0;

  // 得分
  private scoreNum: Laya.Label;

  private clockIndex = 5;

  // 是否播放完
  private clockPlayEnd = true;

  constructor() {
    super();
  }

  // 初始化获取所有的按钮
  private btnsInit(): void {
    for (let i = 0; i < 10; i++) {
      let btn = this.owner.getChildByName("bt" + i) as Laya.Image;
      btn.skin = "resources/single/bt.png";
      btn.off(Laya.Event.CLICK, this, this.btnClick);
      btn.on(Laya.Event.CLICK, this, this.btnClick, [i]);
      btn.off(Laya.Event.DRAG_END, this, this.btnClick);
      btn.on(Laya.Event.DRAG_END, this, this.btnClick, [i]);
      btn.off(Laya.Event.MOUSE_DRAG_END, this, this.btnClick);
      btn.on(Laya.Event.MOUSE_DRAG_END, this, this.btnClick, [i]);
      this.btns[i] = btn;
    }
  }

  // 初始化得分
  private scoreInit() {
    this.scoreLabel = this.owner.getChildByName("scoreLabel") as Laya.Label;
    this.scoreLabel.text = "0";

    this.bobBtn = this.owner.getChildByName("bobBtn") as Laya.Image;
    this.bobBtn.skin = "resources/single/bob_btn1.png";

    this.bobBtn.off(Laya.Event.CLICK, this, this.bobClick);
    this.bobBtn.on(Laya.Event.CLICK, this, this.bobClick);
  }

  /**
   * 组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
   */
  onAwake(): void {
    // 1. 获取UI
    this.btnsInit();

    // 2. 获取得分
    this.scoreInit();

    // 背景音乐
    if (this.curSound != null) {
      this.curSound.stop();
    }
  }

  // 重置值
  reset() {
    Laya.timer.clear(this, this.playCounter);
    if (this.counterClockTween != null) {
      this.counterClockTween.clear();
    }
    // 背景音乐
    if (this.curSound != null) {
      this.curSound.stop();
    }

    this.bob.stateOk();
    this.state = 1;
    this.winLevel = 0;
    this.bob.energy = 0;
    this.useTime = 0;
    this.timeOprLevel = 0;
    this.scoreInit();
    this.btnsInit();
  }

  // 当前播放的音乐
  private curSound: Laya.SoundChannel = null;

  //播放背景音乐
  public playBgm() {
    try { 
      let bgmEunm = "";
      if (this.winLevel <= 3) {
        bgmEunm = SoundEnum.SINGLE_13_BGM;
      } else if (this.winLevel <= 6) {
        bgmEunm = SoundEnum.SINGLE_46_BGM;
      } else {
        // 大于7
        bgmEunm = SoundEnum.SINGLE_7_BGM;
      }
      let bgm = bgmEunm;
      Logger.info("battle播放音乐", bgm);
      Laya.SoundManager.stopMusic();
      this.curSound = Setting.playMusic(bgm, 0);
      this.curSound.url = bgm;
    } catch (e) {
      Logger.info(e);
    }
  }

  // 准备设置
  prepare(): void {
    this.state = 0;
  }

  /**
   * 开始游戏
   */
  start(): void {
    Logger.info("开始了");
    this.play = 0;
    this.state = 1;
    this.clockIndex = 5;
    this.clockPlayEnd = true;
    // 重置值
    this.rightCount = 0;
    this.useTime = 0;
    this.scoreNum.text = "0";
    this.curLevel = this.levels[this.winLevel];
    //this.timeOprLevel = 0;
    if (this.isRage) {
      this.timeOprOnce = this.timeBob;
    } else {
      this.timeOprOnce = this.curLevel.timeOper;
    }

    // 当前几个亮灯
    let light: number = MathUtils.randomIntNM(this.curLevel.lightMin, this.curLevel.lightMax);
    // 随机生成正确的答案
    this.rightAnwsers = MathUtils.RandowSelectInRange(this.btns.length, light, -1);
    Logger.info(this.rightAnwsers, "正确的值");
    this.btns.forEach((item: Laya.Image, index: number) => {
      item.skin = "resources/single/bt.png";
      if (this.rightAnwsers.includes(index)) {
        item.skin = "resources/single/light.png";
      }
    });
    this.anwsers = [];
    this.state = 2;
    this.counterClock();
  }

  //倒计时
  counterClock() {
    this.pointer = this.owner.getChildByName("clock").getChildByName("point") as Laya.Image;
    this.pointer.rotation = 181;
    Laya.timer.clear(this, this.playCounter);
    if (this.counterClockTween != null) {
      this.counterClockTween.clear();
    }
    this.counterClockTween = Laya.Tween.to(this.pointer, { rotation: 541 }, this.curLevel.timeOper * 1000);
  }

  playCounter() {
    Setting.playSound(SoundEnum.CLOCK_COUNTER, 1, Laya.Handler.create(this, () => {
      this.clockPlayEnd = true;
    }));
  }

  // “大爆炸”玩法
  explosion() {
    Logger.info("大爆炸开始了");
    Setting.playSound(SoundEnum.BOB_FULL_ENERGY, 1);
    this.play = 1;
    this.state = 1;
    // 重置值
    this.rightCount = 0;
    this.useTime = 0;
    this.timeOprOnce = 3;

    this.rightAnwsers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    this.btns.forEach((item: Laya.Image, index: number) => {
      item.skin = "resources/single/light.png";
    });
    this.anwsers = [];
    this.state = 2;
  }

  // 点击单个按钮
  btnClick(index: number): void {
    if (this.state == 2) {
      AppContext.vibration({ type: "heavy" });
      Logger.info(index, ":index");
      if (!this.rightAnwsers.includes(index)) {
        Logger.info("btn_over");
        this.btns[index].skin = "resources/single/pressed.png";
        this.gameOver();
      } else {
        if (this.anwsers.includes(index)) {
          return;
        }
        this.btns.forEach((item: Laya.Image, innerIndex: number) => {
          if (innerIndex == index) {
            item.skin = "resources/single/pressed.png";
            if (this.play != 1) {
              // 设置good  prefect
              this.bob.state(this.useTime, this.timeOprOnce);
            }
            this.useTime = 0;
            this.rightCount++;
            this.anwsers.push(index);
            Setting.playSound(SoundEnum.BUTTEN_CLICK, 1);
          }
        });
        if (this.anwsers.length == this.rightAnwsers.length) {
          this.bobBtn.skin = "resources/single/bob_btn1.png";
        }
      }
    }
  }

  // 点击确定键
  bobClick() {
    //Logger.info(this.state);
    if (this.state == 2) {
      // this.bobBtn.skin = "resources/single/bob_btn0.png";
      // let that = this;
      // setTimeout(function () {
      //     that.bobBtn.skin = "resources/single/bob_btn1.png";
      // }, 150)
      AppContext.vibration({ type: "heavy" });
      //Logger.info(this.rightCount, this.rightAnwsers.length);
      if (this.play == 1) {
        this.bob.energy = 0;
      }

      // if(this.isRage){
      //     this.timeOprOnce = this.timeBob;
      // } else{
      //     this.timeOprOnce = this.curLevel.timeOper;
      // }

      //if (this.rightCount == this.rightAnwsers.length) {
      if (this.anwsers.length == this.rightAnwsers.length) {
        this.bobBtn.skin = "resources/single/bob_btn0.png";
        Setting.playSound(SoundEnum.BOB_BOOM, 1);
        this.bob.stateOk();
        if (this.userInfo.bombExp < 50000) {
          //暂设一个上限值5w
          this.userInfo.bombExp += getExp(this.winLevel);
        }
        if (this.play == 1) {
          this.scoreLabel.text = parseInt(this.scoreLabel.text) + this.curLevel.score + "";
          this.start();
        } else {
          this.scoreLabel.text = parseInt(this.scoreLabel.text) + this.curLevel.score + "";
          if (this.curLevel.timeOper - this.timeOprOnce <= 1) {
            // +10%能量
            this.bob.energy += 0.1;
            if (this.bob.energy >= 1) {
              this.bob.energy = 1;
              //触发大爆炸玩法
              this.explosion();
            } else {
              this.start();
            }
          } else {
            this.start();
          }
        }
      } else {
        this.gameOver();
      }
    }
  }

  // 游戏结束
  gameOver() {
    Logger.info("游戏结束");
    this.state = 3;
    Setting.playSound(
      SoundEnum.BUTTEN_FAIL,
      1,
      Laya.Handler.create(this, () => {
        //Setting.playSound(SoundEnum.GAME_OVER, 1);
      })
    );

    // 设置表头
    this.bob.stateFail();

    // 结算
    // this.scoreLabel
    let score = parseInt(this.scoreLabel.text);
    this.scoreNum.text = score + "";
    AppContext.uploadData({ score });

    // 重置信息
    this.reset();
    Single.me.gameOver();
  }

  /**
   * 组件被启用后执行，例如节点被添加到舞台后
   */
  onEnable(): void {
    this.bob = this.owner.getChildByName("bob").getComponent(Bob);
    this.pointer = this.owner.getChildByName("clock").getChildByName("pointer") as Laya.Image;
    this.levels = HelpCache.getValue(ConfigEnum.LEVEL_CONFIG, Level) as Array<Level>;
    this.userInfo = HelpCache.getValue(ConfigEnum.USER_INFO, UserInfo) as UserInfo;
    this.scoreNum = this.owner.parent.getChildByName("over").getChildByName("bg").getChildByName("score").getChildByName("num") as Laya.Label;

    this.back = this.owner.getChildByName("back") as Laya.Image;
    this.back.offAll().on(Laya.Event.CLICK, this, () => {
      if(this.state != 0){
        Laya.SoundManager.stopAll();
        Laya.Scene.open(SceneEnum.Home);
      }
    });
  }

  /**
   * 组件被禁用时执行，例如从节点从舞台移除后
   */
  onDisable(): void {
    Logger.info("[battle]-onDisable");
    if (this.curSound != null) {
      this.curSound.stop();
    }
    Laya.SoundManager.stopAll();
  }

  onDestroy(): void {
    Logger.info("[battle]-onDestroy");
    //if (this.curSound != null) {
    //    this.curSound.stop();
    //}
    //Laya.SoundManager.stopAll();
  }

  /**
   * 每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
   */
  onUpdate(): void {
    if (this.state == 2) {
      this.useTime += Laya.timer.delta * 0.001;
      this.timeOprOnce -= Laya.timer.delta * 0.001;
      this.timeOprLevel += Laya.timer.delta * 0.001;
      if (this.timeOprLevel >= this.curLevel.timeSum) {
        if (this.winLevel >= this.levelMax - 1) {
          //狂暴后
          this.winLevel = this.levelMax - 1;
          this.isRage = true;
          if (this.timeBob > this.timeOprMin) {
            this.timeBob -= this.timeOprReduceR;
          } else {
            this.timeBob = this.timeOprMin;
          }
        } else {
          this.winLevel++;
          this.timeOprLevel = 0;
          if (this.winLevel == 4 || this.winLevel == 7) {
            this.playBgm();
          }
        }
      }
      let floorIndex = Math.floor(this.timeOprOnce * 10)
      if (this.clockIndex == floorIndex) {
        this.clockIndex -= 3
        if (this.clockPlayEnd) {
          this.clockPlayEnd = false;
          this.playCounter()
        }
      }
      if (this.timeOprOnce <= 0) {
        Logger.info(this.timeOprOnce, "timeout");
        this.gameOver();
      }
    }
  }

  /**
   * 每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
   */
  //onLateUpdate(): void {}

  /**
   * 鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
   */
  //onMouseClick(): void {}
}
