import { SoundEnum } from "../help/Help";
import Setting from "../help/Setting";

const { regClass } = Laya;

/**
 * 按钮缩放脚本
 */
@regClass('3KgvaBhMRf6onGA81u7nAQ')
export default class UIScaleComponent extends Laya.Script {
  /**
   * @prop {name:delayTime, tips:"", type:Int, default:100}
   */
  public  delayTime:number = 30;
  /**
   * @prop {name:scaleRadio, tips:"缩小系数", type:Number, default:0.9}
   */
  public  scaleRadio:number = 0.9;
  private _oriScaleX:number = 1;
  private _oriScaleY:number = 1;

  onEnable():void {
    var com:Laya.UIComponent = this.owner as Laya.UIComponent;
    com.anchorX = 0.5;
    com.anchorY = 0.5;

    this._oriScaleX = com.scaleX;
    this._oriScaleY = com.scaleY;

    //添加鼠标按下事件侦听。按下时缩小
    com.on(Laya.Event.MOUSE_DOWN, this, this.scaleSmall);
    //添加鼠标抬起事件侦听。抬起时还原。
    com.on(Laya.Event.MOUSE_UP, this, this.scaleBig);
    //添加鼠标离开事件侦听。离开时还原
    com.on(Laya.Event.MOUSE_OUT, this, this.scaleBig);
  }

  private scaleSmall(): void{
    Setting.playSound(SoundEnum.CLICK_BTN, 1);
    Laya.Tween.to(this.owner, {scaleX:this.scaleRadio, scaleY: this.scaleRadio}, this.delayTime);   //ui上使用自定义的缩放系数，以满足缩放系数较大的控件
  }

  private scaleBig():void{
    //变大还原的缓动效果
    Laya.Tween.to(this.owner, {scaleX:this._oriScaleX, scaleY:this._oriScaleY}, this.delayTime);
  }
}
