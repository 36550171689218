import EventManager from "../../help/EventManager";
import { EvEnum } from "../../help/Help";
import Logger from "../../help/Logger";
import MathUtils from "../../help/MathUtils";
import ApiEnum from "../Api";
import PlatformHelp from "../PlatformHelp";
import Platform from "../base/Platform";
import GameInfo from "../model/GameInfo";
import WxHttpRequest from "./WxHttpRequest";

class WxGame implements Platform {

  // 微信对象
  private wx: any = null;

  constructor() {
    this.wx = Laya.Browser.window.wx;
    if (this.wx) { 
      let that = this;
      // 监听来着微信的转发内容
      // this.wx.onShow((res: any) => {
      //   if (res?.query?.from) { 
      //     Logger.info("来着的分享", res);
      //     Logger.info(res.chatType)
      //     Logger.info(res.query)
      //     let gameInfo = PlatformHelp.getGameInfo();
      //     if ( gameInfo.id != res.query.from) { 
      //       WxHttpRequest.post(ApiEnum.SHARE_APLLY, {
      //         fromId: res.query.from,
      //         toId: gameInfo.id,
      //         chatType: res.chatType
      //       })
      //     }
      //     that.wx.getGroupEnterInfo((res: any) => {
      //       Logger.info("转发到群中", res)
      //     })
      //   }
      // })


      this.wx.showShareMenu({
        withShareTicket: true,
        menus: ['shareAppMessage', 'shareTimeline']
      })

      Logger.info("走到微信中")
      // 点击被动的转发
      this.wx.onShareAppMessage(function () {
        // 用户点击了“转发”按钮
        let d = that.getShareParam();
        let param = {
          ...d,
          complete: function (res: any) { 
            Logger.info("转发完成",res)
            if (res.errMsg == 'shareAppMessage:ok') { 
              if (res.hasOwnProperty('shareTickets')) {
                Logger.info("分享到群", res)
              } else { 
                Logger.info("分享到个人", res)
              }
            }
          }
        }
        return param
      })
      
      // 监听转发情况
      this.wx.onShareMessageToFriend((res:any) => {
        Logger.info("转发成功", res)
      })



    }
  }

  playGame(data: any): Promise<any> {
    let that = this;
    return new Promise((resolve) => {
      WxHttpRequest.post(ApiEnum.PLAY_GAME, data).then((resp) => { 
        that.notify(resp)
        resolve(resp)
      })
    })
  }


  removeCacheValue(key: string): void {
    this.wx.removeStorageSync(key)
  }


  setCacheValue(data: { key: string; value: string; }): void {
    this.wx.setStorageSync(data.key, data.value)
  }

  getCacheValue(key: string): string {
    var value = this.wx.getStorageSync(key)
    return value
  }


  // 拉取世界信息
  rankWorld(data:any): Promise<any> {
    return new Promise((resolve) => {
      WxHttpRequest.post(ApiEnum.RANK_WORLD, data).then((resp) => { 
        resolve(resp)
      })
    })
  }

  postMessage(data: any): void {
    let openDataContext = this.wx.getOpenDataContext()
    openDataContext.postMessage(data)
  }

  /**
   * 
   * @param data 
   */
  uploadData(data: any): void {
    Logger.info("上传数据", data)
    // 上传到服务端
    WxHttpRequest.post(ApiEnum.UPLOAD_DATA, data).then((resp) => {
      Logger.info("服务端返回数据", resp)
      let socreData = {
        "wxgame": resp,
      }
      let openDataContext = this.wx.getOpenDataContext()
      openDataContext.postMessage({
        type: "uploadData",
        data: JSON.stringify(socreData),
      })
    });
  }

  // 微信小程序的震动: heavy medium light 
  vibration(data: any): void {
    this.wx.vibrateShort(data)
  }

  login(): void {
    let that = this;
    this.wx.getSetting({
      success (res: any){
        if (res.authSetting['scope.userInfo']) {
          // 已经授权，可以直接调用 getUserInfo 获取头像昵称
          Logger.info('【已授权】');
          that.wx.getUserInfo({
            success: function (userInfoRes: any) {
              var userInfo = userInfoRes.userInfo
              Logger.info("获取用户信息", userInfo)
              that.wx.login({
                success(logRes: any) {
                  let code = logRes.code;
                  if (code) {
                    WxHttpRequest.post(ApiEnum.LOGIN, { code, type: "wx", ...userInfo }).then((resp) => {
                      if (resp) { 
                        PlatformHelp.setGameInfo(resp);
                        PlatformHelp.setToken(resp.token);
                        that.notify(resp);
                        that.wx.requirePrivacyAuthorize() 
                        that.wx.onNeedPrivacyAuthorization((resolve: any) => {
                          resolve({ event: 'exposureAuthorization' })
                          resolve({ event: 'agree' })
                        })
                      }
                    });
                  } else {
                    Logger.info('登录失败！' + res.errMsg)
                  }
                }
              })
            }
          })
        } else {
          Logger.info('【未授权】');
          // 弹窗授权
          let systemInfo =that.wx.getSystemInfoSync();
          let width = systemInfo.windowWidth;
          let height = systemInfo.windowHeight;
          // 这个游戏在load的时候就创建出来，然后button位置和起卦的位置一致
          let button = that.wx.createUserInfoButton({
            type: 'text',
            text: '',
            style: {
              left: 0,
              top: 0,
              width: width,
              height: height,
              lineHeight: 40,
              backgroundColor: '#00000000',
              color: '#00000000',
              textAlign: 'center',
              fontSize: 10,
              borderRadius: 4
            }
          });
          button.show()
          button.onTap((res: any) => {
            if (res.userInfo) {
              that.wx.login({
                success(logRes: any) {
                  let code = logRes.code;
                  if (code) {
                    WxHttpRequest.post(ApiEnum.LOGIN, { code, type: "wx", ...res.userInfo }).then((resp) => {
                      if (resp) { 
                        PlatformHelp.setGameInfo(resp);
                        PlatformHelp.setToken(resp.token);
                        that.notify(resp);
                        that.wx.requirePrivacyAuthorize() 
                        that.wx.onNeedPrivacyAuthorization((resolve: any) => {
                          resolve({ event: 'exposureAuthorization' })
                          resolve({ event: 'agree' })
                          button.hide();
                          button.destroy();
                        })
                      }
                    });
                  } else {
                    Logger.info('登录失败！' + res.errMsg)
                  }
                }
              })
            }else {
              Logger.info('授权失败！111');
              that.wx.login({
                success(logRes: any) {
                  let code = logRes.code;
                  if (code) {
                    WxHttpRequest.post(ApiEnum.LOGIN, { code, type: "wx"}).then((resp) => {
                      if (resp) { 
                        PlatformHelp.setGameInfo(resp);
                        PlatformHelp.setToken(resp.token);
                        that.notify(resp);
                        button.hide();
                        button.destroy();
                      }
                    });
                  } else {
                    Logger.info('登录失败！' + res.errMsg)
                  }
                }
              })
            }


            button.hide();
            button.destroy();
          });
        }
      },
      fail (res:any) {
        Logger.info('【获取失败】');
      }
    })
  }

  private titles = ['极值解压，考研手速的时候到了', '手速大比拼，看你能过几关', '佛山无影手邀你一战'];

  share(): void {
    let that = this;
    let params = this.getShareParam();
    this.wx.shareAppMessage(params)
    let gameInfo = PlatformHelp.getGameInfo();
    console.log("获取到的用户信息", gameInfo)
    if (gameInfo.id) { 
      WxHttpRequest.post(ApiEnum.SHARE_APLLY, {
        fromId: gameInfo.id
      }).then(resp => {
        if (resp?.id) { 
          Logger.info("返回的值", resp)
          that.notify(resp);
        }
      })
    }
  }

  private getShareParam() { 
    let title = MathUtils.getRandomElement(this.titles)
    let gameInfo = PlatformHelp.getGameInfo();
    let query = "";
    if (gameInfo) { 
      query = "from=" + gameInfo.id;
    }
    Logger.info("gameInfo", gameInfo)
    return {
      title: title,
      query
    }
  }

  // 同步信息给其地方
  private notify(resp: any) { 
    if (resp) { 
      let gameInfo: GameInfo = resp;
      EventManager.emit(EvEnum.UPDATE_GOLD, gameInfo.gold)
      EventManager.emit(EvEnum.UPDATE_SCORE, gameInfo.score)
    }
  }
}


export default new WxGame();