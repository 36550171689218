import EventManager from "../help/EventManager";
import { EvEnum } from "../help/Help";
import MathUtils from "../help/MathUtils";

const { regClass,property } = Laya;

@regClass('aUFH6F-lRD-8xTRubTSKsQ')
export class Score extends Laya.Script {

	@property(Laya.Label)
	public score: Laya.Label;

	@property(Laya.Label)
	public gold: Laya.Label;

	@property(Laya.Label)
	public paiMing: Laya.Label;

	@property(Laya.Image)
	public eye: Laya.Image;

	public skin1: string; 
	public skin0: string; 

	private timer: number = 1;


	constructor() {
		super();
	}

	/**
	 * 组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
	 */
	onAwake(): void {
		this.score = this.owner.getChildByName("score") as Laya.Label;
		this.gold = this.owner.getChildByName("gold") as Laya.Label;
		this.paiMing = this.owner.getChildByName("paiMing") as Laya.Label;

		this.eye = this.owner.getChildByName("gs") as Laya.Image;
		
		// 注册更新用户积分
		EventManager.on(EvEnum.UPDATE_SCORE, this, (score: number) => {
			this.score.text = score + "";
		})

		// 注册更新用户金币
		EventManager.on(EvEnum.UPDATE_GOLD, this, (gold: number) => {
			this.gold.text = gold + "";
		})
	}


	/**
	 * 
	 * @param num 眨眼睛
	 */
	updateEye(num: number) { 
		Laya.timer.clearAll(this);
		Laya.timer.loop(1234, this, this.updateSkin);
	}

	updateSkin() { 
		if (this.timer == 1) {
			this.eye.skin = this.skin0;
			this.timer = 0;
		} else { 
			this.timer = 1;
			this.eye.skin = this.skin1;
		}
	}

	/**
	 * 组件被启用后执行，例如节点被添加到舞台后
	 */
	onEnable(): void {
		let num = MathUtils.randomIntNM(1, 4);
		this.skin1 = "resources/home/gs" + num + "1.png";
		this.skin0 = "resources/home/gs" + num + "0.png";
		this.eye.skin = this.skin1;
		this.updateEye(num);
	}

	/**
	 * 组件被禁用时执行，例如从节点从舞台移除后
	 */
	//onDisable(): void {}

	/**
	 * 第一次执行update之前执行，只会执行一次
	 */
	//onStart(): void {}

	/**
	 * 手动调用节点销毁时执行
	 */
	//onDestroy(): void {

	/**
	 * 每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	 */
	//onUpdate(): void {}

	/**
	 * 每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	 */
	//onLateUpdate(): void {}

	/**
	 * 鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
	 */
	//onMouseClick(): void {}
}