/**
 * 数学工具类
 */
export default class MathUtils {
  /**
   * 向上取整
   * @param  {number} value
   * @return number
   */
  public static ceil(value: number): number {
    if (value % 1 == 0) {
      return value
    }
    if (value > 0) {
      return (value + 1) << 0
    } else {
      return value << 0
    }
  }
  /**
   * 向下取整
   * @param  {number} value
   * @return number
   */
  public static floor(value: number): number {
    return Math.floor(value)
  }

  /**
   * 返回一个在min和max之间的随机浮点数
   */
  public static range(min: number, max: number): number {
    return Math.random() * (max - min) + min
  }

  /**
   * [min,max)范围内随机一个整数
   * @param  {number} min 最小值
   * @param  {number} max 最大值
   * @return number   随机值
   */
  public static random(min: number, max: number): number {
    return (Math.random() * (max - min) + min) << 0
  }

  /**
   * (n,m]范围内随机一个整数
   * @param  {number} min 最小值
   * @param  {number} max 最大值
   * @return number   随机值
   */
  public static randomintM(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min) + 1
  }
  /**
   * (n,m)范围内随机一个整数
   * @param  {number} min 最小值
   * @param  {number} max 最大值
   * @return number  随机值
   */
  public static randomInt(min: number, max: number): number {
    return Math.round(Math.random() * (max - min - 2) + min + 1)
  }
  /**
   * [n,m]范围内随机一个整数
   * @param  {number} min 最小值
   * @param  {number} max 最大值
   * @return number   随机值
   */
  public static randomIntNM(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min)
  }


  /**
   * [n,m]范围内随机一个整数
   * @param  {number} min 最小值
   * @param  {number} max 最大值
   * @return number   随机值
   */
  public static randomIntNMCount(min: number, max: number, count: number): Array<number> {
    let result: Array<number> = [];
    for (let i = 0; i < count; i++) { 
      let tmp = this.randomIntNM(min, max);
      if (!result.includes(tmp)) { 
        result.push(tmp);
      }
    }
    return result
  }

  /**
   * 数组内随机一个元素
   * @param  {T[]} arr    数组
   * @return T
   */
  public static getRandomElement<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)]
  }

  // 从[0~range)范围内，随机选出num个数,排除掉p,p不在范围内就不排除
  public static RandowSelectInRange(range: number, num: number, p: number): Array<number> {
    if(range < num || range <= 0 || num <= 0){return null;}

    let result: Array<number> = new Array<number>();

    let m: Array<number> = new Array<number>();
    for(let i: number = 0; i < range; ++i){
      if(i != p){
        m.push(i);
      }
    }
    result = Array.from(m).sort(() => 0.5 - Math.random());
    result = result.slice(0, num);
    return result;
}

  /**
   * 计算两点距离
   * @param p1
   * @param p2
   * @return {number}
   */
  public static distance(p1: Laya.Point, p2: Laya.Point): number {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
  }

  //计算两点的距离
  public static dis(centerX: number, centerY: number, mouseX: number, mouseY: number) {
    let dx: number = centerX - mouseX
    let dy: number = centerY - mouseY
    let distance: number = Math.sqrt(dx * dx + dy * dy)
    return distance
  }

  /**
   * 返回半径为1的圆内的一个随机点
   */
  public static insideUnitCircle(): Laya.Point {
    var randomAngle: number = this.range(0, 360)
    var randomDis: number = this.range(0, 1)
    var randomX: number = randomDis * Math.cos((randomAngle * Math.PI) / 180)
    var randomY: number = randomDis * Math.sin((randomAngle * Math.PI) / 180)
    return new Laya.Point(randomX, randomY)
  }
  /**
   * 返回半径为1的圆边的一个随机点
   */
  public static onUnitCircle(): Laya.Point {
    var randomAngle: number = this.range(0, 360)
    var randomX: number = Math.cos((randomAngle * Math.PI) / 180)
    var randomY: number = Math.sin((randomAngle * Math.PI) / 180)
    return new Laya.Point(randomX, randomY)
  }
  /**
   * 弧度制转换为角度值
   * @param radian 弧度制
   * @returns {number}
   */
  public static getAngle(radian: number): number {
    return (180 * radian) / Math.PI
  }
  /**
   * 角度值转换为弧度制
   * @param angle
   */
  public static getRadian(angle: number): number {
    return (angle / 180) * Math.PI
  }
  /**
   * 获取两点间弧度(水平方向)
   * @param p1X
   * @param p1Y
   * @param p2X
   * @param p2Y
   * @returns {number}
   */
  public static getRadian2(p1X: number, p1Y: number, p2X: number, p2Y: number): number {
    var xdis: number = p2X - p1X
    var ydis: number = p2Y - p1Y
    return Math.atan2(ydis, xdis)
  }
  /**
   * 获取两点间角度(水平方向)
   * @param p1X
   * @param p1Y
   * @param p2X
   * @param p2Y
   * @returns {number}
   */
  public static getAngle2(p1X: number, p1Y: number, p2X: number, p2Y: number): number {
    return this.getAngle(this.getRadian2(p1X, p1Y, p2X, p2Y))
  }
  /**
   * 限定value在min和max之间
   */
  public static clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value))
  }
  /**
   * 限定value在0和1之间
   */
  public static clamp01(value: number): number {
    return this.clamp(value, 0, 1)
  }

  /**
   * 打扰数组
   * @param arr 原数组
   * @returns 打扰后的数组
   */
  public static shuffle(arr: Array<any>): Array<any> {
    let i = arr.length
    while (i) {
      let j = Math.floor(Math.random() * i--)
      ;[arr[j], arr[i]] = [arr[i], arr[j]]
    }
    return arr
  }

  /**
   * 取一个随机值
   * @param percent 百分比
   * @returns 是否是在百分比中
   */
  public static percentage(percent: number): boolean {
    let arr = new Array<number>()
    for (let i = 1; i <= 100; i++) {
      arr.push(i)
    }
    let data = this.getRandomElement(this.shuffle(arr))
    return data <= percent
  }

  public Angle(from: Laya.Vector3, to: Laya.Vector3): number {
    let num = Math.sqrt(this.SqrMagnitude(from) * this.SqrMagnitude(to))
    if (num < Number.MIN_VALUE) {
      return 0
    }
    let f = this.Clamp(this.Dot(from, to) / num, -1, 1)
    return Math.acos(f) * 57.29578
  }

  public Dot(lhs: Laya.Vector3, rhs: Laya.Vector3): number {
    return lhs.x * rhs.x + lhs.y * rhs.y + lhs.z * rhs.z
  }

  public Clamp(value: number, min: number, max: number): number {
    if (value < min) {
      value = min
    } else if (value > max) {
      value = max
    }
    return value
  }

  public SqrMagnitude(vector: Laya.Vector3): number {
    return vector.x * vector.x + vector.y * vector.y + vector.z * vector.z
  }

  // 钱的数值显示格式化
  public static FormatMoney(money: number): string {
    let str: string = money.toString()
    if (money >= 1000 && money < 1000 * 1000) {
      str = (Math.floor((money / 1000.0) * 10) / 10.0).toFixed(1).toString() + "K"
    } else if (money >= 1000 * 1000 && money <= 1000 * 1000 * 1000) {
      str = (Math.floor((money / (1000.0 * 1000)) * 10) / 10.0).toFixed(1).toString() + "M"
    } else if (money >= 1000 * 1000 * 1000) {
      str =
        Math.floor(((money / (1000.0 * 1000 * 1000)) * 10) / 10.0)
          .toFixed(1)
          .toString() + "B"
    }
    return str
  }

  public static arr2str(arr: number[], s: string): string {
    let str: string = arr.join(s)
    return str
  }

}
