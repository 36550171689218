import Logger from "../../help/Logger";
import ApiEnum from "../Api";
import PlatformHelp from "../PlatformHelp";
import HttpRequest from "../base/HttpRequest";

let wx = Laya.Browser.window.wx;

// 定义函数，返回一个 Promise，还得要发起一个请求
function http(params: any) {

  // 解构获取默认的参数（baseURL）
  const { baseURL } = http.defaults

  // 将服务器地址与路径进行拼接
  params.url = baseURL + params.url

  return new Promise((reslove, reject) => {

    // 调用请求拦截器
    params = http.interceptors.request(params)


    Logger.info("请求参数", params)

    // 请求之前调用 API 提示用户去等待请求
    http.queue.length === 0 && wx.showLoading({
      title: '正在加载...',
      mask: true
    })

    // 成功的回调函数，变更 Promise 成功的状态
    params.success = (res: any) => {
      wx.hideLoading();
      Logger.info("请求成功", params)
      if (res.statusCode != 200) {
        wx.showToast({
          icon: "none",
          title: '网络异常,请稍等',
          duration: 4000
        })
      } else {
        let result = http.interceptors.response(res.data);
        if (!result) {
          reslove(false)
        } else {
          // 调用拦截器
          Logger.info("请求返回数据", result)
          reslove(result)
        }
      }
    }

    // 失败的回调函数，变更 Promise 失败的状态
    params.fail = (err: any) => {
      wx.hideLoading();
      if (err.errMsg == 'request:fail timeout') {
        wx.showToast({
          icon: 'none',
          title: '请求超时,请稍后重试',
          duration: 3000,
        })
      } else {
        reject(err)
      }
    }

    // 等待请求完成后会调用 complete 回调函数
    params.complete = () => {
      // 调用 API 隐藏掉加载提示框

      // 当请求结束后，将数组 http.queue 长度减 1
      http.queue.pop()

      // 当所有请求都结束后，http.queue 长度会再次为 0
      http.queue.length === 0 && wx.hideLoading()
    },

    // 向数组 http.queue 中追加新的单元
    // 有几次请求数组就会有几个单元
    http.queue.push('loading')

    params.timeout = 15000
    // 发起请求
    wx.request(params)
  })

}

// 定义数组记录请求的数量
http.queue = new Array<any>();

// 定义 defaults 对象，用来存 http 的默认参数
http.defaults = {
  baseURL: ApiEnum.baseUrl
}

// 定义拦截器
http.interceptors = {

  // 请求拦截器
  request(params: any) {
    // 在内部处理数据
    let token = PlatformHelp.getToken();
    return Object.assign({}, params, {
      header: {
        "roorz": token
      },
    })
  },

  // 响应拦截器
  response(data: any) {
    let code = data.code
    let msg = data.msg
    if (code != '200') {
      wx.showToast({
        icon: 'none',
        duration: 5000,
        title: msg,
      })
      return false;
    }
    return data.data || {};
  }

}

// 并发处理
http.all = (...promises:Array<Promise<any>>) => {
  return Promise.all(promises)
}

// 快捷方法（专门用于 get 请求）
http.get = (url: string, data: any) => {
  return http({
    url,
    method: 'get',
    data
  })
}

// 快捷方法（专门用于 post 请求）
http.post = (url: string, data: any) => {
  return http({
    url,
    method: 'post',
    data
  })
}


/**
 * 微信小程序的http请求
 */
class WxHttpRequest implements HttpRequest{

  get(url: string, data: any): Promise<any> {
    return http.get(url, data);
  }

  post(url: string, data: any): Promise<any> {
    return http.post(url, data);
  }
}

export default new WxHttpRequest()


