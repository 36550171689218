import AppContext from "../app/AppContext";
import EventManager from "../help/EventManager";
import { EvEnum } from "../help/Help";
import { Settings } from "./Settings";

const { regClass, property } = Laya;


@regClass('qgRLtgdCRsWfrZKjWm1kwQ')
export class Header extends Laya.Script {
	//declare owner : Laya.Sprite3D;
	@property(Laya.Label)
	public gold: Laya.Label;

	@property(Laya.Image)
	public share: Laya.Image;

	@property(Laya.Image)
	public setting: Laya.Image;

	constructor() {
		super();
	}

	/**
	 * 组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
	 */
	onAwake(): void {
		this.gold = this.owner.getChildByName("gold") as Laya.Label;
		this.share = this.owner.getChildByName("share") as Laya.Image;
		this.share.on(Laya.Event.CLICK, this, () => AppContext.share())
		// 注册更新用户金币
		EventManager.on(EvEnum.UPDATE_GOLD, this, (gold: number) => {
			this.gold.text = gold + "";
		})

		this.setting = this.owner.getChildByName("setting") as Laya.Image;
		this.setting.on(Laya.Event.CLICK, this, () => {
			(this.owner.parent.getChildByName("setting") as Laya.Sprite).visible = true;
		})
	}

	/**
	 * 组件被启用后执行，例如节点被添加到舞台后
	 */
	//onEnable(): void {}

	/**
	 * 组件被禁用时执行，例如从节点从舞台移除后
	 */
	//onDisable(): void {}

	/**
	 * 第一次执行update之前执行，只会执行一次
	 */
	//onStart(): void {}

	/**
	 * 手动调用节点销毁时执行
	 */
	//onDestroy(): void {

	/**
	 * 每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	 */
	//onUpdate(): void {}

	/**
	 * 每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	 */
	//onLateUpdate(): void {}

	/**
	 * 鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
	 */
	//onMouseClick(): void {}
}