import { SoundEnum } from "../help/Help";
import HelpCache from "../help/HelpCache";
import Platform from "./base/Platform";
import PlatformHelp from "./PlatformHelp";

class AppContext implements Platform {

  playGame(): Promise<any> {
    let platform: Platform = PlatformHelp.getPlat();
    let gameInfo = PlatformHelp.getGameInfo();
    if (gameInfo && gameInfo.id) {
      if (platform) {
        return PlatformHelp.getPlat().playGame({id: gameInfo.id})
      }
    }
  }

  setCacheValue(data: { key: string; value: string; }): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().setCacheValue(data)
    }
  }
  removeCacheValue(key: string): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().removeCacheValue(key)
    }
  }

  
  getCacheValue(key: string): string {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      return PlatformHelp.getPlat().getCacheValue(key)
    }
  }

  rankWorld(data:any): Promise<any> {
    let platform: Platform = PlatformHelp.getPlat();
    let gameInfo = PlatformHelp.getGameInfo();
    if (gameInfo && gameInfo.openid) {
      if (platform) {
        return PlatformHelp.getPlat().rankWorld(data)
      }
    }
  }

  postMessage(data: any): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().postMessage(data)
    }
  }

  uploadData(data: any): void {
    let gameInfo = PlatformHelp.getGameInfo();
    if (gameInfo && gameInfo.id) { 
      // 填充ID
      let id = gameInfo.id;
      let param = {
        id, ...data
      }
      PlatformHelp.getPlat().uploadData(param);
    }
  }
  
  vibration(data: any): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().vibration(data)
    }
  }

  login(): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().login()
    }
  }

  share(): void {
    let platform: Platform = PlatformHelp.getPlat();
    if (platform) {
      PlatformHelp.getPlat().share()
    }
  } 

  private inited:boolean = false;

  /**
   * 初始
   */
  init() { 
    if (!this.inited) {
      this.inited = true;
      // 初始化的时候加载配置
      HelpCache.loadAllConfig();
    }

    // 登录
    if (!Laya.Browser.onPC) { 
      this.login();
    }

    // 加载背景音乐
    Laya.loader.load(SoundEnum.HOME_BGM);
    Laya.loader.load(SoundEnum.SINGLE_13_BGM);
    Laya.loader.load(SoundEnum.SINGLE_46_BGM);
    Laya.loader.load(SoundEnum.SINGLE_7_BGM);
  }

}

export default new AppContext();