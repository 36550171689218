import Platform from "./base/Platform";
import GameInfo from "./model/GameInfo";
import WxGame from "./wx/WxGame";


/**
 * 平台相关信息
 */
class PlatformHelp { 

  private platforms = new Map<String, Platform>();

  // 本地的token
  private gameInfo: GameInfo;
 
  constructor() {
    // 先注册平台
    this.platforms.set("onMiniGame", WxGame);
  }

  /**
   * 获取平台
   * @returns 
   */
  public getPlat(): Platform { 
    if (Laya.Browser.onMiniGame || Laya.Browser.onWeiXin) { 
      return this.platforms.get("onMiniGame");
    }
    return null;
  }

  /**
   * 设置用户信息
   * @param gameInfo 
   */
  public setGameInfo(gameInfo: GameInfo): void { 
    this.gameInfo = gameInfo;
  }

  /**
   * 设置用户信息
   * @param gameInfo 
   */
  public getGameInfo(): GameInfo { 
    return this.gameInfo;
  }

  /**
   * 获取token
   * @returns 
   */
  public getToken(): string { 
    if (this.gameInfo) { 
      return this.gameInfo.token  || "";
    }
    return this.gameInfo?.token || "";
  }

   /**
   * 获取token
   * @returns 
   */
  public setToken(token: string): void { 
    this.gameInfo.token = token || '';
  }
}

export default new PlatformHelp();
