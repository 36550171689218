const { regClass } = Laya;

/**
 * 基础的配置
 */
@regClass('FjdtraKCTIexvLHOtFonUw')
export default class GameConfig extends Laya.Script {
  
  private width:number=750;
  private height:number=1500;
  private scaleMode:string="fixedwidth";
  private screenMode:string="vertical";
  private alignV:string="middle";
  private alignH: string = "center";


  onAwake(): void {
    this.init()
  }


  init() { 
    Laya.init(this.width, this.height, Laya["WebGL"])
    Laya.stage.scaleMode = this.scaleMode
    Laya.stage.screenMode = this.screenMode
    Laya.stage.alignV = this.alignV
    Laya.stage.alignH = this.alignH

    console.log("Laya.Browser.onMiniGame",Laya.Browser.onMiniGame)

    if (Laya.Browser.onMiniGame) {
      Laya.URL.basePath = "https://press.roorz.com/remote/"
    }
  }
}
