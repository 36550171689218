const { regClass, property } = Laya;
import { SoundEnum, startTasks } from "../help/Help";
import Logger from "../help/Logger";
import Setting from "../help/Setting";

@regClass('pGQSzJ99QJGkbFpQVR9r9w')
export default class Mask extends Laya.Script {   
	@property({type:Laya.Label})
	public timing: Laya.Label;

	@property({type:Number})
	private total: number = 3;

	constructor() {
		super();
	}

	/**
	 * 开始倒计时
	 */
	async start(): Promise<void> {
		Logger.info("开始倒计时");
		this.total = 3;
		(this.owner as Laya.Sprite).visible = true;
		this.counterOne();
		await startTasks([this.counterOne, this.counterOne, ()=>{}], this);
		(this.owner as Laya.Sprite).visible = false;
	}
	/**
	 * 倒计时
	 */
	counterOne(): void { 
		this.timing.text = (this.total--) + "";
		Setting.playSound(SoundEnum.DOWN_COUNTER, 1);
	}
}