export default class Level {

  constructor() {

  }

  //关卡总时间
  public timeSum: number;

  //按钮亮的最小个数
  public lightMin: number;
  
  //按钮亮的最大个数
  public lightMax: number;

  //单个操作最大时间
  public timeOper: number;

  //正常过关得分
  public score: number;

}
