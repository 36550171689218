const { regClass, property } = Laya;

import AppContext from '../app/AppContext';
import { SceneEnum, SoundEnum } from '../help/Help';
import Logger from '../help/Logger';
import { OpenData } from './OpenData';


@regClass('Jhw81X7-ToSO0OGLmDyvCg')
export class Footer extends Laya.Script {
	@property(Laya.Image)
	public danRen: Laya.Image;

	@property(Laya.Image)
	public jingJi: Laya.Image;

	@property(Laya.Image)
	public haoYou: Laya.Image;

	@property(Laya.Image)
	public paiMing: Laya.Image;

    @property(Laya.Image)
    public loading: Laya.Image;

    @property(Laya.Label)
    public progress: Laya.Label;

    private isLoading: boolean = false;
    private tmpProgress: number = 1;

    constructor() {
        super();
    }

    /**
     * 组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
     */
    onAwake(): void {
        this.danRen = this.owner.getChildByName("danRen") as Laya.Image;
        // this.jingJi = this.owner.getChildByName("jingJi") as Laya.Image;
        // this.haoYou = this.owner.getChildByName("haoYou") as Laya.Image;
        this.paiMing = this.owner.getChildByName("paiMing") as Laya.Image;
        this.loading = this.owner.parent.getChildByName("loading") as Laya.Image;
        this.loading.visible = false;
        this.progress = this.loading.getChildByName("progress") as Laya.Label;
        
        this.danRen.offAll()
        this.danRen.on(Laya.Event.CLICK, async () => {
			Logger.info("danRen")
            if(Laya.Browser.onMiniGame == true){
                let resp = await AppContext.playGame();
                if (resp) {
                    this.clickIcon(SceneEnum.Single)
                }
            }
            else{
                this.clickIcon(SceneEnum.Single)
            }
		})

		// this.jingJi.on(Laya.Event.CLICK, () => {
		//     Logger.info("jingJi")
		//     this.clickIcon(SceneEnum.Single)
		// })

		// this.haoYou.on(Laya.Event.CLICK, () => {
		//     Logger.info("haoYou")
		//     this.clickIcon(SceneEnum.Single)
		// })

		this.paiMing.on(Laya.Event.CLICK, () => {
			Logger.info("paiMing");
			let openData = this.owner.parent.getChildByName("wxOpenData").getComponent(OpenData);
			openData.start();
			Logger.info("paiMing2");
		})
	}

    /**
     * 
     */
    clickIcon(scene: SceneEnum) { 
        if(this.isLoading == true){
            return;
        }
        let bgm = SoundEnum.HOME_ICON;
        Laya.loader.load(bgm).then((resp) => { 
            Laya.SoundManager.playSound(bgm, 1, Laya.Handler.create(this, () => {
                Laya.SoundManager.stopAll()
                Laya.Scene.open(scene, true, null, null, Laya.Handler.create(this, (progress: number)=>{
                    Logger.info(progress.toFixed(2));
                    this.progress.text = (progress * 100).toFixed(0) + "%";
                }))
                this.loading.visible = true;
                this.isLoading = true;
            }));
        })
    }

    /**
     * 每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
     */
    onUpdate(): void {
        if(this.isLoading == true){
            if(this.tmpProgress < 100){
                this.tmpProgress += 1; 
                this.progress.text = "LOADING......" + this.tmpProgress + "%";
            }
            else{
                this.progress.text = "正在解压资源，请稍后......";
            }
        }
    }

	/**
	 * 每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
	 */
	//onLateUpdate(): void {}

	/**
	 * 鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
	 */
	//onMouseClick(): void {}
}