

/**
 * 缓存的枚举
 */
export enum ConfigEnum { 
  LEVEL_CONFIG="resources/config/level_cfg.json",
  USER_INFO = "resources/config/data.json",
}

class HelpCache {

  // 保存有的缓存配置
  private cacheData: Map<String, JSON> = new Map();

  /**
   * 加载配置
   */
  public async loadAllConfig(){
    for (let key in ConfigEnum) { 
      //@ts-ignore
      let path: string = ConfigEnum[key + ""];
      let json = await Laya.loader.load(path);
      this.cacheData.set(path, json.data)
    }
  }

  /**
   * 
   * @param config 配置的类型
   * @param T 返回的对象
   * @returns 可以返回Array, 单个对象,或者完整的JSON
   */
  getValue<String, T>(config: ConfigEnum, T?:any): Array<T> | T | JSON{ 
    let data:JSON = this.cacheData.get(config);
    if (T != null) {
      return JSON.parse(JSON.stringify(data), { ...T })
    }
    return data;
  }
}

export default new HelpCache();