import AppContext from "../app/AppContext";
import { SoundEnum } from "../help/Help";
import Logger from "../help/Logger";
import Setting from "../help/Setting";

const { regClass, property } = Laya;

@regClass('opdTUaG6SGq__vIQjh4tVA')
export class Home extends Laya.Script { 

  // 是否可以播放
  private playEnable: boolean = true;

  private curSound:Laya.SoundChannel = null;
  
  public static me: Home;

  constructor() { 
    super();
    Home.me = this;
  }

  onAwake(): void {
    // 项目的初始化
    AppContext.init();
    Laya.SoundManager.stopAll();
  }

  onEnable(): void {
    let that = this;
    that.playBgm();
  }

  playBgm() { 
    let bgm = SoundEnum.HOME_BGM;
    Logger.info("播放" + bgm)
    let that = this;
    Laya.loader.load(bgm).then((resp) => {
      Logger.info("加载成功," + bgm)
      if (this.playEnable) {
        Laya.SoundManager.stopMusic();
        this.curSound = Setting.playMusic(bgm, 0, new Laya.Handler(this, function () {
          if (that.curSound) {
            that.curSound.url = bgm; 
          }
         }), 0);
      }
    });
  }

  onDisable(): void {
    Logger.info("[home]-onDisable")
    Laya.SoundManager.stopMusic();
  }

  /**
   * 手动调用节点销毁时执行
   */
  onDestroy(): void { 
    Logger.info("[home]-onDestroy")
    this.playEnable = false;
    if (this.curSound != null) { 
      this.curSound.stop();
    }
    Laya.SoundManager.stopAll();
  }
}