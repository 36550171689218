import AppContext from "../app/AppContext";


/**
 * 相关的设置
 */
class Setting {

  /**
   * 是否播放音效
   */
  private playSoundEnabled: boolean = true;


  /**
   * 是否播放背景音乐
   */
  private playMusicEnabled: boolean = true;


  private me: Laya.SoundChannel = new Laya.SoundChannel();


  constructor() {
    let flag1 = AppContext.getCacheValue("playMusicEnabled");
    if (flag1 === "false") {
      this.playMusicEnabled = false
    }else if (flag1 === "true") {
      this.playMusicEnabled = true
    } 

    let flag2 = AppContext.getCacheValue("playSoundEnabled");
    if (flag2 === "false") {
      this.playSoundEnabled = false
    }else if (flag2 === "true") {
      this.playSoundEnabled = true
    } 
  }


  // 播放背景音乐
  public playMusic(url: string, loops?: number, complete?: Laya.Handler, startTime?: number): Laya.SoundChannel {
    if (this.playMusicEnabled) { 
      return Laya.SoundManager.playMusic(url, loops, complete, startTime)
    }
    if (complete) { 
      complete.runWith(arguments)
    }
    return this.me;
  }


  // 播放背景音效
  public playSound(url: string, loops?: number, complete?: Laya.Handler, soundClass?: new () => any, startTime?: number): Laya.SoundChannel {
    if (this.playSoundEnabled) { 
      return Laya.SoundManager.playSound(url, loops, complete, soundClass, startTime)
    }

    if (complete) { 
      complete.runWith(arguments)
    }

    return this.me;
  }

  /**
   * 设置是否播放
   * @param flag 
   */
  public triggerPlayMusic(flag: boolean): void { 
    if (!flag) {
      Laya.SoundManager.stopMusic()
    }

    AppContext.setCacheValue({key: "playMusicEnabled", value: flag + ""})
    this.playMusicEnabled = flag;
  }


  /**
   * 设置是否播放
   * @param flag 
   */
  public triggerPlaySound(flag: boolean): void { 
    if (!flag) {
      Laya.SoundManager.stopAllSound()
    }
    AppContext.setCacheValue({key: "playSoundEnabled", value: flag + ""})
    this.playSoundEnabled = flag;
  }

  /**
   * 获取当前的背景音乐播放情况
   */
  public getPlayMusicEnabled(): boolean { 
    let flag = AppContext.getCacheValue("playMusicEnabled");
    if (flag === "false") {
      this.playMusicEnabled = false
    }else if (flag === "true") {
      this.playMusicEnabled = true
    } 
    return this.playMusicEnabled;
  }


  /**
   * 获取当前的背景音乐播放情况
   */
  public getPlaySoundEnabled(): boolean { 
    let flag = AppContext.getCacheValue("playSoundEnabled");
    if (flag === "false") {
      this.playSoundEnabled = false
    }else if (flag === "true") {
      this.playSoundEnabled = true
    } 
    return this.playMusicEnabled;
  }
}


export default new Setting()