/**
 * 自定义事件
 */
class EventManager {

  private ev: Laya.EventDispatcher;

  constructor() {
    this.ev = new Laya.EventDispatcher()
  }

  /**
   * 触发事件
   * @param eventName 事件名
   * @param args 参数
   */
  public emit(eventName: string, args?: any): boolean {
    return this.ev.event(eventName, args)
  }

  /**
   * 使用 EventDispatcher 对象注册指定类型的事件侦听器对象，以使侦听器能够接收事件通知。
   * @param eventName     事件的类型。
   * @param caller    事件侦听函数的执行域。
   * @param listener  事件侦听函数。
   * @param args       （可选）事件侦听函数的回调参数。
   */
  public on(eventName: string, caller: any, listener: Function, args?: any[]): Laya.EventDispatcher {
    return this.ev.on(eventName, caller, listener, args == null ? null : [args])
  }

  /**
   * 从 EventDispatcher 对象中删除侦听器。
   * @param eventName     事件的类型。
   * @param caller    事件侦听函数的执行域。
   * @param listener  事件侦听函数。
   * @param onceOnly  （可选）如果值为 true ,则只移除通过 once 方法添加的侦听器。
   */
  public remove(eventName: string): Laya.EventDispatcher {
    return this.ev.offAll(eventName)
  }
}

export default new EventManager();