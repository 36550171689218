/**
 * 日志类
 */
export default class Logger{



  // 是否开启日志
  private static env: boolean = true;

  /**
   * 打印参数
   * @param msg 任意的参数
   */
  static info(msg:any, ...param:any) : void{ 
    if (this.env) { 
      if (typeof msg == 'string') { 
        if (param.length == 0) {
          console.log(this.date() + " " + msg)
        }else if (param.length == 1) {
          console.log(this.date() + " " + msg, param[0])
        } else { 
          console.log(this.date() + " " + msg, param)
        }
      } else {
        if (param.length == 0) {
          console.log(this.date(), msg)
        }else if (param.length == 1) {
          console.log(this.date(), msg, param[0])
        } else { 
          console.log(this.date(), msg, param)
        }
      }


      
    }
  }


  /**
   * 打印参数
   * @param msg 任意的参数
   */
  static error(msg:any, ...param:any) : void{ 
    if (this.env) { 
      if (param.length == 0) {
        console.error(msg)
      } else { 
        console.error(msg, param)
      }
    }
  }


  private static date() {
    let date = new Date();
    let year = date.getFullYear();
    //@ts-ignore
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
     //@ts-ignore
    let day = date.getDate().toString().padStart(2, '0');
     //@ts-ignore
    let hours = date.getHours().toString().padStart(2, '0');
     //@ts-ignore
    let minutes = date.getMinutes().toString().padStart(2, '0');
     //@ts-ignore
    let seconds = date.getSeconds().toString().padStart(2, '0');

    let milliseconds = date.getMilliseconds()
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDate;
  }
}