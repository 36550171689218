import MathUtils from "./MathUtils";

/**
 * 场景
 */
enum SceneEnum{
  Single = "scene/single.ls", // 单人模式,
  Home = "scene/home.ls", // 首屏,
  

  Rank = "scene/rank.ls", // 排行榜
}


/**
 * 声音资源
 */
enum SoundEnum{

  // 首页
  HOME_BGM = "resources/media/bgm/home_bgm.mp3",
  // 单人模式
  SINGLE_13_BGM = "resources/media/bgm/bob13.mp3",  // 1-3关
  SINGLE_46_BGM = "resources/media/bgm/bob46.mp3",  // 4-6关
  SINGLE_7_BGM = "resources/media/bgm/bob7.mp3",  // >=7关

  

  // 倒计时
  DOWN_COUNTER = "resources/media/hit.wav",

  // 游戏结束
  GAME_OVER = "resources/media/gameover_1.wav",

  // 按bob键
  BOB_BOOM = "resources/media/bob_boom.wav",

  // 大爆炸开始了
  BOB_FULL_ENERGY = "resources/media/bob_fullenergy.wav",

  // 点击单个
  BUTTEN_CLICK = "resources/media/butten_1.wav",

  //单个按钮失败
  BUTTEN_FAIL = "resources/media/butten_fail.wav",

  // 闹钟倒计时
  CLOCK_COUNTER = "resources/media/clock.wav",

  // 游戏开始
  GAME_START = "resources/media/start_1.wav",


  // 首页点击图标
  HOME_ICON = "resources/media/menu_touch.wav",


  // 点击任意按钮情况
  CLICK_BTN = "resources/media/click.mp3",

}

// 单人模式，bomb经验获取
function getExp(level: number): number {
  let exp = 0;
  if(level >= 0 && level <= 2){
    exp = MathUtils.randomIntNM(5, 10);
  }
  else if(level >= 3 && level <= 5){
    exp = MathUtils.randomIntNM(10, 15);
  }
  else if(level >= 6){
    exp = MathUtils.randomIntNM(20, 25);
  }
  return exp;
}


/**
 * 自定义事件
 */
enum EvEnum { 

  // 更新首页的gold
  UPDATE_GOLD = "update_gold",


  // 更新首页用户的积分
  UPDATE_SCORE = "update_score",

}

/**
 * 开启一连串的任务
 * @param {*} 传入函数 
 */
async function startTasks(funcArr: Array<Function>, that: Object, time = 1000) {
  let tasks = [];
  for (let i = 0; i < funcArr.length; i++) {
    tasks.push(
      delay(funcArr[i], that, time * (i + 1))
    )
  }

  let result:any = []
  return tasks.reduce((accumulator, item, index) => {
    return accumulator.then(res => {
      return item.then(res => {
        result[index] = res
        return index == tasks.length - 1 ? result : item
      })
    })
  }, Promise.resolve())
}


/**
 * 延迟
 * @param {*} func 
 * @param {*} time 
 */
function delay(func: Function, that: Object, time: number) {
  return new Promise<void>(function (resolve, reject) {
    setTimeout(function () {
      if (that) {
        func = func.bind(that)
      } 
      func()
      resolve();
    }, time);
  });
}



export {
  SceneEnum,startTasks,delay,SoundEnum,EvEnum,getExp
}