import { delay } from "../help/Help";

const { regClass, property } = Laya;

let bobConfig = {
	good: {
		skin: "resources/single/good.png",
		width: 252,
		height: 84
	},
	perfect: {
		skin: "resources/single/perfect.png",
		width: 252,
		height: 84
	},

	ok: {
		skin: "resources/single/bob_boomcloud_1.png",
		width: 780,
		height: 616
	},

	fail: {
		skin: "resources/single/bob_boomcloud_2.png",
		width: 764,
		height: 624
	}
}


@regClass('7h8WIJJnQ_GKc0BSwXnMCw')
export default class Bob extends Laya.Script {   
 
	@property({type:Laya.Image})
	public bob: Laya.Image;

	public energy: number = 0;

	constructor() {
		super();
	}

	onEnable() { 
		this.bob = this.owner.getChildByName("bob") as Laya.Image;
		this.bob.visible = false;
	}


	/**
	 * 设置状态
	 */
	state(useTime: number, timeOprOnce: number): number { 
		if (useTime / timeOprOnce < 0.5) {
			this.bob.visible = true;
			this.bob.skin = bobConfig.perfect.skin;
			// this.bob.width = bobConfig.perfect.width;
			// this.bob.height = bobConfig.perfect.height;

			delay(function () {
				//@ts-ignore
				this.bob.visible = false;
			}, this, 400);

			return 20;
		} else if (useTime / timeOprOnce < 0.8) { 
			this.bob.visible = true;
			this.bob.skin = bobConfig.good.skin;
			// this.bob.width = bobConfig.good.width;
			// this.bob.height = bobConfig.good.height;

			delay(function () {
				//@ts-ignore
				this.bob.visible = false;
			}, this, 400)

			return 20;
		} else {
			return 10;
		}     
	}

	// 成功
	stateOk() { 
		this.bob.skin = bobConfig.ok.skin;
		// this.bob.width = bobConfig.ok.width;
		// this.bob.height = bobConfig.ok.height;
	}

	// 失败
	stateFail() { 
		this.bob.skin = bobConfig.fail.skin;
		// this.bob.width = bobConfig.fail.width;
		// this.bob.height = bobConfig.fail.height;
	}
}