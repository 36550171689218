export default class UserInfo {

  constructor() {

  }

  //用户的名字
  public name: string;

  //用当前的等级
  public level: number;

  //用户的得分
  public score: number;

  //bomb养成总经验
  public bombExp: number;

}
